import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

let _root = this;

export default class Parallax {
    constructor() {
        _root = this;
        this.refresh();
    }

    refresh() {
        this.setSize();
    }

    setSize() {
        if (window.innerWidth >= 992) {
            this.startMoveElements();
            this.startMoveBgParallax();
        }
        this.startBgColor();
    }


    startMoveElements() {

        let element = document.querySelectorAll('.parallax-item');

        if ('undefined' != typeof element && null != element) {

            const tl = gsap.timeline({
                scrollTrigger: {
                    scrub: true
                }
            });

            gsap.utils.toArray(".parallax-item").forEach(layer => {
                const depth = layer.dataset.depth;
                const movement = -(layer.offsetHeight * depth);

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: layer.parentElement,
                        scrub: true
                    }
                });

                tl.to(
                    layer, {
                        y: movement,
                        ease: "none"
                    }, 0);

                if (layer.dataset.rotate) {
                    tl.to(
                        layer.querySelector('path'), {
                            rotation: layer.dataset.rotate,
                            scale: 0.8,
                            transformOrigin: "center center",
                            ease: "none"
                        }, 0);
                }
            });

        }

    }

    startMoveBgParallax() {

        let element = document.querySelectorAll('.parallax-img__container');

        if ('undefined' != typeof element && null != element) {

            gsap.utils.toArray(".parallax-img__container .parallax-img__img").forEach(section => {
                const heightDiff = section.offsetHeight - section.parentElement.offsetHeight;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: section.parentElement,
                        scrub: true
                    }
                });

                let direction = -heightDiff;

                if (section.dataset.inverted) {
                    direction = -(heightDiff/2);

                 
                } 

                tl.to(
                    section, {
                        y: direction,
                        ease: "none"
                    }, 0);
            });
        }
    }

    startBgColor() {

        gsap.utils.toArray(".section").forEach(function(elem) {

            var color = elem.getAttribute('data-color');
            var textcolor = elem.getAttribute('data-textcolor');
            
            ScrollTrigger.create({
                trigger: elem,
                pin: '.images',
                start:'top center',
                end:'bottom 50%',
                onEnter: () => gsap.to('main', {
                    backgroundColor: color,
                    color: textcolor
                }), 
                onLeave: () => gsap.to('main', {
                    backgroundColor: 'white',
                    color: '#4d5154'
                }), 
                onLeaveBack: () => gsap.to('main', {
                    backgroundColor: 'white',
                    color: '#4d5154'
                }), 
                onEnterBack: () => gsap.to('main', {
                    backgroundColor: color,
                    color: textcolor
                }), 
                markers:false
            });

        });

        // gsap.utils.toArray('.section').forEach((section, i) => {
  
        // if(section.getAttribute('data-color') !== null) {
            
        //     var colorAttr = section.getAttribute('data-color')
            
        //     gsap.to("#main", {
        //         backgroundColor: colorAttr,
        //         // color: colorAttr,
        //         immediateRender: false,
        //         scrollTrigger: {
        //             trigger: section,
        //             scrub: true,
        //             start:'top bottom',
        //             end: '+=100%'
        //         }
        //         });

        //     }
        
        // });
    }

}