import Swiper from 'swiper/bundle';

let _root = this;

export default class SliderGallery {
  constructor(root) {
    _root = this;
    this.initSliderGallery();
  }

  initSliderGallery() {
    var interleaveOffset = 0.5;

    var swiperOptions = {
      loop: false,
      speed: 300,
      grabCursor: true,
      watchSlidesProgress: true,
      init: false,
      preloadImages: false,
      parallax: true,
      autoplay: {
          delay: 6000,
          disableOnInteraction: false,
      },
      lazy: true,
      pagination: {
        el: '.slider-gallery-swiper__wrapper .swiper-pagination',
        clickable: true,
        renderBullet: function(index, className) {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      on: {
        progress: function() {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            let slideProgress = swiper.slides[i].progress;
            let innerOffset = swiper.width * interleaveOffset;
            let innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector('.swiper-slide-picture').style.transform =
              'translate3d(' + innerTranslate + 'px, 0, 0)';
          }
        },
        touchStart: function() {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = '';
          }
        },
        setTransition: function(speed) {
          let swiper = this;
          for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = speed + 'ms';
            swiper.slides[i].querySelector('.swiper-slide-picture').style.transition = speed + 'ms';
          }
        }
      }
    };

    let _swiperContainer = document.querySelector('.slider-gallery-swiper');

    let swiper = new Swiper(_swiperContainer, swiperOptions);

    if ('undefined' != typeof _swiperContainer && null != _swiperContainer) {
      swiper.init();
    }

  }
}
